<template>
  <div>
    <!-- <v-btn :to="{ name: 'epunewPat', params: { editUser: { ...pat } } }">Edit</v-btn> -->
    <h1 class="text-center pt-3">Patients List</h1>
    <div
      class="d-flex justify-center align-center"
      style="width: 30%; margin: auto"
    >
      <v-select
        :items="exporttype"
        @change="exportChange"
        label="Export desired selection:"
        v-model="exportMode"
      >
      </v-select>
      <v-select
        v-if="exportoption"
        :items="exportoptions"
        @change="initaitExportByLocationAndEntry"
        :label="title"
        v-model="exportoptionvalue"
      >
      </v-select>

      <input
        @input="initaitExportByHospName"
        v-if="exportnameinput"
        v-model="patient_hosp_name"
        type="text"
        placeholder="Enter Hosp Name"
      />
      <input
        v-if="exportyearinput"
        @input="initaitExportByYear"
        v-model.number="exportyear"
        type="number"
        placeholder="Enter Year "
      />
    </div>
    <div class="d-flex justify-center align-center flex-column">
      <p class="ma-0 pa-0">{{ patientsdataAll.length }} Patients Found</p>

      <p class="ma-0 pa-0">{{ nameFound }}</p>
    </div>
    <v-row justify="center" class="mt-5">
      <!-- <v-col cols="auto" class="mt-n5">
        <b>Enter the year to Download:</b>
        <v-text-field v-model="year" :color="color" placeholder="2019" outlined filled dense @input="downloadExcel">
        </v-text-field>
      </v-col> -->

      <v-col cols="auto">
        <vue-excel-xlsx
          :disabled="exportready"
          :data="patientsdataAll"
          :columns="excelHeaders"
          :file-name="'Patient Lists'"
          :sheet-name="'Patient Sheets'"
        >
          <v-btn
            :disabled="exportready"
            small
            rounded
            class="white--text"
            :color="color"
          >
            Export {{ exporttitle }} to Excel</v-btn
          >
        </vue-excel-xlsx>
      </v-col>
    </v-row>
    <v-row justify="end" class="pb-5">
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          :color="color"
          single-line
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="patientHeaders"
      :items="patients"
      :items-per-page="5"
      :search="search"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          x-small
          class="mr-2"
          :to="{ name: 'epunewPat', params: { editUser: { ...item } } }"
          title="Click to edit this patient"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          @click="delPatient(item)"
          title="Click to delete this patient"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.created_by="{ value }">
        {{ value }}
      </template>
      <template v-slot:item.modified_by="{ value }">
        {{ value }}
      </template>
      <template v-slot:item.created_on="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.modified_on="{ value }">
        {{ formatDate(value) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <h2 class="success">Demographic data</h2>
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="mt-3">Maternal Demographics</h3>
                <h5><b>Address:</b> {{ item.demo_data.address }}</h5>
                <h5>
                  <b>Contact Phone Number on case note:</b>
                  {{ item.demo_data.phoneno }}
                </h5>

                <h5>
                  <b>Age (years as of last birthday):</b>
                  {{ item.demo_data.age + item.demo_data.ageUnit }}
                </h5>
                <h5><b>Date of Birth:</b> {{ item.demo_data.dob }}</h5>
                <h5>
                  <b>Number of children in the family:</b>
                  {{ item.demo_data.no_of_children }}
                </h5>
                <h5>
                  <b>Position of patient in the family:</b>
                  {{ item.demo_data.child_position }}
                </h5>
                <h5><b>Sex:</b> {{ item.demo_data.sex }}</h5>
                <h5 v-if="item.entry_point == 'EPU'">
                  <b>Admission Date:</b> {{ item.admissionDate }}
                </h5>
                <h5 v-if="item.entry_point == 'EPU'">
                  <b>Discharge Date:</b> {{ item.dischargeDate }}
                </h5>
                <h5 v-if="item.entry_point == 'EPU'">
                  <b>Admission Duration:</b> {{ item.admissionDuration }}
                </h5>
                <h5>
                  <b>Parent Marital Status:</b>
                  {{ item.demo_data.marital }}
                </h5>
                <h5 v-if="item.demo_data.marital == 'Married'">
                  <b>Family Structure:</b>
                  {{ item.demo_data.married }}
                </h5>
                <h5>
                  <b>Mother's Highest Level of Education:</b>
                  {{ item.demo_data.matEdu }}
                </h5>
                <h5>
                  <b>Father's Highest Level of Education:</b>
                  {{ item.demo_data.patEdu }}
                </h5>
                <h5>
                  <b>Mother&apos;s Occupation:</b>
                  {{ item.demo_data.matOccup }}
                </h5>
                <h5>
                  <b>Father&apos;s Occupation:</b>
                  {{ item.demo_data.patOccup }}
                </h5>
              </v-col>

              <v-col cols="12" md="6"> </v-col>
            </v-row>

            <h2 class="mt-5 success">Presentation Data</h2>
            <v-row>
              <v-col cols="12"  >
                <h3 class="my-2">Complaints At Presentation:</h3>
                <div
                  v-for="(complain, index) in item.present.illness"
                  :key="index"
                  class=""
                >
                  <div
                    v-if="complain.isActive == true"
                    class="d-flex align-center justify-start"
                  >
                    {{ complain.complaint }}:
                    <span class="red--text ml-1">
                      {{ complain.duration }} Days</span
                    >
                  </div>
                </div>
                <h5 v-if="item.present.convulsion.status">
                  <b>Convulsion in current illness:</b>
                  <p>Seizure: {{ item.present.convulsion.seizure }}</p>
                  <p>Episodes: {{ item.present.convulsion.episode }}</p>
                </h5>
                <h5 class="ma-0 pa-0">
                  <b>
                    Any previous episodes:
                    <span v-if="item.present.episode.status"
                      >Yes <br />
                      <span>How Many: {{ item.present.episode.howmany }}</span>
                    </span>
                    <span class="pa-0 ma-0 red--text" v-else>No</span>
                  </b>
                </h5>
                <h5 class="ma-0 pa-0">
                  <b
                    >Blood Transfusion:
                    <span v-if="item.present.bloodTransfussion"
                      >Yes <br />
                    </span>
                    <span class="pa-0 ma-0 red--text" v-else>No</span>
                  </b>
                </h5>
                <h3 class="my-1">Previous Treatment</h3>
                <h5 class="">
                  <b></b>
                  <p class="ma-0 pa-0">
                    Any Maleria Drug Given:
                    <span class="red--text">{{ item.present.previous }}</span>
                  </p>

                  <p v-if="item.present.previous == 'Yes'" class="ma-0 pa-0">
                    Drugs used prior to Presentation:
                    <span
                      v-for="(drug, index) in item.present.priordrug"
                      :key="index"
                      class="red--text mr-2"
                      >{{ drug }}</span
                    >
                  </p>

                  <p v-if="item.present.previous == 'Yes'" class="ma-0 pa-0">
                    Places child was treated:
                    <span
                      v-for="(drug, index) in item.present.placetreated"
                      :key="index"
                      class="red--text mr-2"
                      >{{ drug }}</span
                    >
                  </p>
                </h5>
                <h3 class="mt-2">CLINICAL SIGNS AT PRESENTATION</h3>
                <h5><b>Height/Length(cm):</b> {{ item.present.height }}</h5>
                <h5><b>Weight(kg):</b> {{ item.present.weight }}</h5>
                <h5><b>OFC(cm):</b> {{ item.present.ofc }}</h5>
                <h5><b>MUAC(cm):</b> {{ item.present.muac }}</h5>
                <h5><b>BMI(kg/m²):</b> {{ item.present.bmi }}</h5>
                <h5><b>Tempreature(℃):</b> {{ item.present.tempreature }}</h5>
                <h5><b>Pallor:</b> {{ item.present.pallor }}</h5>
                <h5><b>Jaundice:</b> {{ item.present.jaundice }}</h5>
                <h5><b>Oedema:</b> {{ item.present.oedema }}</h5>
                <h5>
                  <b>Respiratory Rate(min):</b> {{ item.present.respiratory }}
                </h5>
                <h5><b>Respiratory Distress:</b> {{ item.present.chestin }}</h5>
                <h5><b>Lungs fields:</b> {{ item.present.lungfield }}</h5>
                <h5><b>Heart Rate(min):</b> {{ item.present.heartrate }}</h5>
                <h5>
                  <b>Blood Pressure(mm/Hg):</b> {{ item.present.bloodpressure }}
                </h5>
                <h5><b>Mumur:</b> {{ item.present.mumur }}</h5>
              </v-col>
              <v-col cols="12" sm="6">
                <h3 class="mt-2">CLINICAL SIGNS AT PRESENTATION</h3>
                <h5><b>Liver Size(cm):</b> {{ item.present.liversize }}</h5>
                <h5><b>Spleen(cm):</b> {{ item.present.spleensize }}</h5>
                <h5><b>Coma Score(GCS):</b> {{ item.present.comascore }}</h5>
                <h5><b>Posture:</b> {{ item.present.posture }}</h5>
                <div>
                  <h5>
                    <b>Power:</b>
                    <span>RUL: {{ item.present.power.rul }}</span>
                    <span>LUL: {{ item.present.power.lul }}</span>
                    <span>RLL: {{ item.present.power.rll }}</span>
                    <span>LLL: {{ item.present.power.lll }}</span>
                  </h5>
                </div>
                <h5><b>Tone:</b> {{ item.present.tone }}</h5>
                <h5><b>Cranial Nerves:</b> {{ item.present.cranialnerve }}</h5>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <h2 class="mt-5 success">Treatment And Outcome</h2>
                <h5 class="mt-2">
                  <b>Admission Diagnosis(es):</b>
                  {{ item.outcome.admissiondiagnosis }}
                </h5>
                <h5>
                  <b>Final Diagnosis:</b>
                  {{ item.outcome.finaldiagnosis }}
                </h5>
                <h5>
                  <b>Duration of Admission:</b>
                  {{ item.outcome.durationadmission }}
                </h5>

                <h5>
                  <b>Drugs Given:</b>
                  {{ item.outcome.givendrug }}
                </h5>
                <h5>
                  <b>Outcome:</b>
                  {{ item.outcome.outcome }}
                </h5>
                <h5>
                  <b>Blood Transfusion:</b>
                  {{ item.outcome.bloodtransfussion }}
                </h5>
                <h5>
                  <b>Overall Outcome:</b>
                  {{ item.outcome.overalloutcome }}
                </h5>
              </v-col>
              <v-col cols="12" sm="6">
                <h2 class="mt-5 success">Folow Up</h2>
                <div v-if="item.followUpOnAdmission.date">
                  <h4>Follow Follow Up OnAdmission</h4>
                  <h5><b>Date:</b> {{ item.followUpOnAdmission.date }}</h5>
                  <h5>
                    <b>Urea(mg/dl):</b> {{ item.followUpOnAdmission.urea }}
                  </h5>
                  <h5>
                    <b>Creatinine (ὕmol/l):</b>
                    {{ item.followUpOnAdmission.creatinine }}
                  </h5>
                  <h5>
                    <b>BF for Mp Parasite:</b> {{ item.followUpOnAdmission.bf }}
                  </h5>
                  <h5>
                    <b>G6PD Screening:</b>
                    {{ item.followUpOnAdmission.g6pdScreening }}
                  </h5>
                  <h5>
                    <b>PVC on Admission:</b> {{ item.followUpOnAdmission.pvc }}
                  </h5>
                  <h5>
                    <b>G6PD Result:</b>
                    {{ item.followUpOnAdmission.g6pdResult }}
                  </h5>
                  <h5><b>WCB + diff:</b> {{ item.followUpOnAdmission.wcb }}</h5>
                  <h5>
                    <b>Haemoglobin Type:</b
                    >{{ item.followUpOnAdmission.haemoglobinType }}
                  </h5>
                  <h5>
                    <b>Platelet:</b> {{ item.followUpOnAdmission.platelet }}
                  </h5>
                  <h5>
                    <b>Urinalysis:</b>{{ DestructUniralysis(item.followUpOnAdmission ? item.followUpOnAdmission.urinalysis : {} )}}
                  </h5>
                  <h5>
                    <b>HB Genotype:</b>
                    {{ item.followUpOnAdmission.hbGenotype }}
                  </h5>
                  <h5>
                    <b>Urine microscopy:</b>
                    {{ item.followUpOnAdmission.urineMicroscopy }}
                  </h5>
                  <h5>
                    <b>Sodium(mmmol/l):</b>
                    {{ item.followUpOnAdmission.sodium }}
                  </h5>
                  <h5>
                    <b>Blood culture:</b
                    >{{ item.followUpOnAdmission.bloodCulture }}
                  </h5>
                  <h5>
                    <b>Potassium:</b>{{ item.followUpOnAdmission.potassium }}
                  </h5>
                  <h5>
                    <b>CXR findings:</b
                    >{{ item.followUpOnAdmission.cxrFindings }}
                  </h5>
                  <h5>
                    <b>Chloride:</b>{{ item.followUpOnAdmission.chloride }}
                  </h5>
                  <h5>
                    <b>Pvc at discharge:</b
                    >{{ item.followUpOnAdmission.pvcDischarge }}
                  </h5>
                  <h5>
                    <b>Bicorbonate:</b
                    >{{ item.followUpOnAdmission.bicarbonate }}
                  </h5>
                  <h5>
                    <b>Serial Blood Pressure:</b
                    >{{ item.followUpOnAdmission.seriealBloodPressure }}
                  </h5>
                </div>
              </v-col>
              <v-col v-if="item.followUpWeekTwo.date" cols="12" sm="6">
                <div>
                  <h4>Follow Follow Up Week 2</h4>
                  <h5><b>Date:</b> {{ item.followUpWeekTwo.date }}</h5>
                  <h5><b>Urea(mg/dl):</b> {{ item.followUpWeekTwo.urea }}</h5>
                  <h5>
                    <b>Creatinine (ὕmol/l):</b>
                    {{ item.followUpWeekTwo.creatinine }}
                  </h5>
                  <h5>
                    <b>BF for Mp Parasite:</b> {{ item.followUpWeekTwo.bf }}
                  </h5>
                  <h5>
                    <b>G6PD Screening:</b>
                    {{ item.followUpWeekTwo.g6pdScreening }}
                  </h5>
                  <h5>
                    <b>PVC on Admission:</b> {{ item.followUpWeekTwo.pvc }}
                  </h5>
                  <h5>
                    <b>G6PD Result:</b> {{ item.followUpWeekTwo.g6pdResult }}
                  </h5>
                  <h5><b>WCB + diff:</b> {{ item.followUpWeekTwo.wcb }}</h5>
                  <h5>
                    <b>Haemoglobin Type:</b
                    >{{ item.followUpWeekTwo.haemoglobinType }}
                  </h5>
                  <h5><b>Platelet:</b> {{ item.followUpWeekTwo.platelet }}</h5>
                  <h5>
                    <b>Urinalysis:</b>{{  DestructUniralysis(item.followUpWeekTwo ? item.followUpWeekTwo.urinalysis : {} ) }}
                  </h5>
                  <h5>
                    <b>HB Genotype:</b> {{ item.followUpWeekTwo.hbGenotype }}
                  </h5>
                  <h5>
                    <b>Urine microscopy:</b>
                    {{ item.followUpWeekTwo.urineMicroscopy }}
                  </h5>
                  <h5>
                    <b>Sodium(mmmol/l):</b> {{ item.followUpWeekTwo.sodium }}
                  </h5>
                  <h5>
                    <b>Blood culture:</b>{{ item.followUpWeekTwo.bloodCulture }}
                  </h5>
                  <h5><b>Potassium:</b>{{ item.followUpWeekTwo.potassium }}</h5>
                  <h5>
                    <b>CXR findings:</b>{{ item.followUpWeekTwo.cxrFindings }}
                  </h5>
                  <h5><b>Chloride:</b>{{ item.followUpWeekTwo.chloride }}</h5>
                  <h5>
                    <b>Pvc at discharge:</b
                    >{{ item.followUpWeekTwo.pvcDischarge }}
                  </h5>
                  <h5>
                    <b>Bicorbonate:</b>{{ item.followUpWeekTwo.bicarbonate }}
                  </h5>
                  <h5>
                    <b>Serial Blood Pressure:</b
                    >{{ item.followUpWeekTwo.seriealBloodPressure }}
                  </h5>
                </div>
              </v-col>

              <v-col v-if="item.followUpWeekFour.date" cols="12" sm="6">
                <div>
                  <h4>Follow Follow Up Week 4</h4>
                  <h5><b>Date:</b> {{ item.followUpWeekFour.date }}</h5>
                  <h5><b>Urea(mg/dl):</b> {{ item.followUpWeekFour.urea }}</h5>
                  <h5>
                    <b>Creatinine (ὕmol/l):</b>
                    {{ item.followUpWeekFour.creatinine }}
                  </h5>
                  <h5>
                    <b>BF for Mp Parasite:</b> {{ item.followUpWeekFour.bf }}
                  </h5>
                  <h5>
                    <b>G6PD Screening:</b>
                    {{ item.followUpWeekFour.g6pdScreening }}
                  </h5>
                  <h5>
                    <b>PVC on Admission:</b> {{ item.followUpWeekFour.pvc }}
                  </h5>
                  <h5>
                    <b>G6PD Result:</b> {{ item.followUpWeekFour.g6pdResult }}
                  </h5>
                  <h5><b>WCB + diff:</b> {{ item.followUpWeekFour.wcb }}</h5>
                  <h5>
                    <b>Haemoglobin Type:</b
                    >{{ item.followUpWeekFour.haemoglobinType }}
                  </h5>
                  <h5><b>Platelet:</b> {{ item.followUpWeekFour.platelet }}</h5>
                  <h5>
                    <b>Urinalysis:</b>{{  DestructUniralysis(item.followUpWeekFour.urinalysis ? item.followUpWeekEight.urinalysis : {}) }}
                  </h5>
                  <h5>
                    <b>HB Genotype:</b> {{ item.followUpWeekFour.hbGenotype }}
                  </h5>
                  <h5>
                    <b>Urine microscopy:</b>
                    {{ item.followUpWeekFour.urineMicroscopy }}
                  </h5>
                  <h5>
                    <b>Sodium(mmmol/l):</b> {{ item.followUpWeekFour.sodium }}
                  </h5>
                  <h5>
                    <b>Blood culture:</b
                    >{{ item.followUpWeekFour.bloodCulture }}
                  </h5>
                  <h5>
                    <b>Potassium:</b>{{ item.followUpWeekFour.potassium }}
                  </h5>
                  <h5>
                    <b>CXR findings:</b>{{ item.followUpWeekFour.cxrFindings }}
                  </h5>
                  <h5><b>Chloride:</b>{{ item.followUpWeekFour.chloride }}</h5>
                  <h5>
                    <b>Pvc at discharge:</b
                    >{{ item.followUpWeekFour.pvcDischarge }}
                  </h5>
                  <h5>
                    <b>Bicorbonate:</b>{{ item.followUpWeekFour.bicarbonate }}
                  </h5>
                  <h5>
                    <b>Serial Blood Pressure:</b
                    >{{ item.followUpWeekFour.seriealBloodPressure }}
                  </h5>
                </div>
              </v-col>

              <v-col v-if="item.followUpWeekEight.date" cols="12" sm="6">
                <div>
                  <h4>Follow Follow Up Week 8</h4>
                  <h5><b>Date:</b> {{ item.followUpWeekEight.date }}</h5>
                  <h5><b>Urea(mg/dl):</b> {{ item.followUpWeekEight.urea }}</h5>
                  <h5>
                    <b>Creatinine (ὕmol/l):</b>
                    {{ item.followUpWeekEight.creatinine }}
                  </h5>
                  <h5>
                    <b>BF for Mp Parasite:</b> {{ item.followUpWeekEight.bf }}
                  </h5>
                  <h5>
                    <b>G6PD Screening:</b>
                    {{ item.followUpWeekEight.g6pdScreening }}
                  </h5>
                  <h5>
                    <b>PVC on Admission:</b> {{ item.followUpWeekEight.pvc }}
                  </h5>
                  <h5>
                    <b>G6PD Result:</b> {{ item.followUpWeekEight.g6pdResult }}
                  </h5>
                  <h5><b>WCB + diff:</b> {{ item.followUpWeekEight.wcb }}</h5>
                  <h5>
                    <b>Haemoglobin Type:</b
                    >{{ item.followUpWeekEight.haemoglobinType }}
                  </h5>
                  <h5>
                    <b>Platelet:</b> {{ item.followUpWeekEight.platelet }}
                  </h5>
                  <h5>
                    <b>Urinalysis:</b>{{  DestructUniralysis(item.followUpWeekEight.urinalysis ? item.followUpWeekEight.urinalysis : {}) }}
                  </h5>
                  <h5>
                    <b>HB Genotype:</b> {{ item.followUpWeekEight.hbGenotype }}
                  </h5>
                  <h5>
                    <b>Urine microscopy:</b>
                    {{ item.followUpWeekEight.urineMicroscopy }}
                  </h5>
                  <h5>
                    <b>Sodium(mmmol/l):</b> {{ item.followUpWeekEight.sodium }}
                  </h5>
                  <h5>
                    <b>Blood culture:</b
                    >{{ item.followUpWeekEight.bloodCulture }}
                  </h5>
                  <h5>
                    <b>Potassium:</b>{{ item.followUpWeekEight.potassium }}
                  </h5>
                  <h5>
                    <b>CXR findings:</b>{{ item.followUpWeekEight.cxrFindings }}
                  </h5>
                  <h5><b>Chloride:</b>{{ item.followUpWeekEight.chloride }}</h5>
                  <h5>
                    <b>Pvc at discharge:</b
                    >{{ item.followUpWeekEight.pvcDischarge }}
                  </h5>
                  <h5>
                    <b>Bicorbonate:</b>{{ item.followUpWeekEight.bicarbonate }}
                  </h5>
                  <h5>
                    <b>Serial Blood Pressure:</b
                    >{{ item.followUpWeekEight.seriealBloodPressure }}
                  </h5>
                </div>
              </v-col>

              <v-col v-if="item.followUpWeekTwelve.date" cols="12" sm="6">
                <div>
                  <h4>Follow Follow Up Week 12</h4>
                  <h5><b>Date:</b> {{ item.followUpWeekTwelve.date }}</h5>
                  <h5>
                    <b>Urea(mg/dl):</b> {{ item.followUpWeekTwelve.urea }}
                  </h5>
                  <h5>
                    <b>Creatinine (ὕmol/l):</b>
                    {{ item.followUpWeekTwelve.creatinine }}
                  </h5>
                  <h5>
                    <b>BF for Mp Parasite:</b> {{ item.followUpWeekTwelve.bf }}
                  </h5>
                  <h5>
                    <b>G6PD Screening:</b>
                    {{ item.followUpWeekTwelve.g6pdScreening }}
                  </h5>
                  <h5>
                    <b>PVC on Admission:</b> {{ item.followUpWeekTwelve.pvc }}
                  </h5>
                  <h5>
                    <b>G6PD Result:</b> {{ item.followUpWeekTwelve.g6pdResult }}
                  </h5>
                  <h5><b>WCB + diff:</b> {{ item.followUpWeekTwelve.wcb }}</h5>
                  <h5>
                    <b>Haemoglobin Type:</b
                    >{{ item.followUpWeekTwelve.haemoglobinType }}
                  </h5>
                  <h5>
                    <b>Platelet:</b> {{ item.followUpWeekTwelve.platelet }}
                  </h5>
                  <h5>
                    <b>Urinalysis:</b
                    >{{
                      DestructUniralysis(item.followUpWeekTwelve ? item.followUpWeekTwelve.urinalysis : {})
                    }}
                  </h5>
                  <h5>
                    <b>HB Genotype:</b> {{ item.followUpWeekTwelve.hbGenotype }}
                  </h5>
                  <h5>
                    <b>Urine microscopy:</b>
                    {{ item.followUpWeekTwelve.urineMicroscopy }}
                  </h5>
                  <h5>
                    <b>Sodium(mmmol/l):</b> {{ item.followUpWeekTwelve.sodium }}
                  </h5>
                  <h5>
                    <b>Blood culture:</b
                    >{{ item.followUpWeekTwelve.bloodCulture }}
                  </h5>
                  <h5>
                    <b>Potassium:</b>{{ item.followUpWeekTwelve.potassium }}
                  </h5>
                  <h5>
                    <b>CXR findings:</b
                    >{{ item.followUpWeekTwelve.cxrFindings }}
                  </h5>
                  <h5>
                    <b>Chloride:</b>{{ item.followUpWeekTwelve.chloride }}
                  </h5>
                  <h5>
                    <b>Pvc at discharge:</b
                    >{{ item.followUpWeekTwelve.pvcDischarge }}
                  </h5>
                  <h5>
                    <b>Bicorbonate:</b>{{ item.followUpWeekTwelve.bicarbonate }}
                  </h5>
                  <h5>
                    <b>Serial Blood Pressure:</b
                    >{{ item.followUpWeekTwelve.seriealBloodPressure }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  alluser,
  deletADoc,
  onSnapshot,
} from '@/Auth/index';
import { snackbar } from '@/main.js';

export default {
  data() {
    return {
      patsList: [],
      color: '#024202',
      exportready: true,
      exporttype: [
        'Export all patients',
        'Export all patients in ado',
        'Export all patients in ido',
        'Export individual patients',
        'Export all patients By year',
      ],
      exportoption: false,
      exportoptions: [],
      exporttitle: '',
      title: '',
      exportoptionvalue: '',
      exportMode: '',
      exportModeValue: '',
      exportnameinput: false,
      exportyearinput: false,
      exportyear: 0,
      patient_hosp_name: '',
      nameFound: '',
      expanded: [],
      patients: [],
      patientsdata: [],
      patientsdataAll: [],
      patientsdataSync: [],
      excelHeaders: [],
      search: '',
      // columns: excelData,
      year: '',
      patientHeaders: [
        {
          text: 'Patient Name',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'name',
        },
        {
          text: 'Hospital No',
          sortable: true,
          filterable: true,
          value: 'hosp_no',
        },
        {
          text: 'Entry Unit',
          sortable: true,
          filterable: true,
          value: 'entry_point',
        },
        {
          text: 'Location',
          sortable: true,
          filterable: true,
          value: 'location',
        },
        {
          text: 'Details',
          value: 'data-table-expand',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  created() {
    this.getAllPatients();
  },
  methods: {
    async getAllPatients() {
      onSnapshot(alluser('patients'), (snapshot) => {
        let count = 0;
        this.patients = [];
        this.patsList = [];
        this.patientsdataAll = [];
        this.patientsdataSync = [];
        snapshot.forEach((doc) => {
          let allowExtract = false;
          if (count == snapshot.size - 1) {
            allowExtract = true;
          }
          this.patsList.push({ ...doc.data(), id: doc.id });
          this.patients.push({ ...doc.data(), id: doc.id });
          this.patientsdataAll.push({
            ...this.destructurePatient(doc.data(), allowExtract),
          });
          this.patientsdataSync.push({
            ...this.destructurePatient(doc.data()),
            allowExtract,
          });
          count += 1;
        });
      });
    },
    destructurePatient(doc, extract = false) {
      let present_placetreated = doc.present.placetreated
        ? this.destructurePlaceTreated(doc.present.placetreated)
        : '';
      let illness = doc.present.illness
        ? this.destructureIllness(doc.present.illness)
        : '';
      // let present_illness =

      const data = {
        name: doc.name,
        entry_point: doc.entry_point,
        location: doc.location,
        hosp_no: doc.hosp_no,
        createdYear: doc.createdYear,
        // start demo_data destructuring
        age: doc.demo_data.age + ' ' + doc.demo_data.ageUnit,
        admissionDate: doc.admissionDate,
        dischargeDate: doc.dischargeDate,
        admissionDuration: doc.admissionDuration,
        address: doc.demo_data.address,
        child_position: doc.demo_data.child_position,
        dob: doc.demo_data.dob,
        marital: doc.demo_data.marital,
        married:
          doc.demo_data.marital == 'Married'
            ? doc.demo_data.married
            : 'Not Married',
        matOccup: doc.demo_data.matOccup,
        matEdu: doc.demo_data.matEdu,
        no_of_children: doc.demo_data.no_of_children,
        patEdu: doc.demo_data.patEdu,
        patOccup: doc.demo_data.patOccup,
        phoneno: doc.demo_data.phoneno,
        sex: doc.demo_data.sex,
        tribe: doc.demo_data.tribe,
        // start present destructuring
        present_bloodpressure: doc.present.bloodpressure,
        present_bmi: doc.present.bmi,
        present_chestin: doc.present.chestin,
        present_comascore: doc.present.comascore,
        present_heartrate: doc.present.heartrate,
        present_height: doc.present.height,
        present_jaundice: doc.present.jaundice,
        present_liversize: doc.present.liversize,
        present_lungfield: doc.present.lungfield,
        present_muac: doc.present.muac,
        present_mumur: doc.present.mumur,
        present_neckstiff: doc.present.neckstiff,
        present_odema: doc.present.oedema,
        present_ofc: doc.present.ofc,
        present_pallor: doc.present.pallor,
        present_posture: doc.present.posture,
        present_previous: doc.present.previous,
        present_respiratory: doc.present.respiratory,
        present_spleensize: doc.present.spleensize,
        present_temperature: doc.present.temperature,
        present_tone: doc.present.tone,
        present_weight: doc.present.weight,
        present_cranialnerve: doc.present.cranialnerve,
        present_episode_howmany: doc.present.episode.howmany,
        present_episode_status: doc.present.episode.status,
        present_convulsion_episode: doc.present.convulsion.episode,
        present_convulsion_seizure: doc.present.convulsion.seizure,
        present_convulsion_status: doc.present.convulsion.status,
        present_power_lll: doc.present.power.lll,
        present_power_lul: doc.present.power.lul,
        present_power_rll: doc.present.power.rll,
        present_power_rul: doc.present.power.rul,
        present_placetreated: present_placetreated,
        present_illness: illness,
        // end present destructuring
        // start of present outcome
        outcome_admissiondiagnosis: doc.outcome.admissiondiagnosis,
        outcome_bloodtransfusion: doc.outcome.bloodTransfussion,
        outcome_durationadmission: doc.outcome.durationadmission,
        outcome_finaldiagnosis: doc.outcome.finaldiagnosis,
        outcome_givendrug: doc.outcome.givendrug,
        outcome_overalloutcome: doc.outcome.overalloutcome,
        // end of outcome and start of followup
        followup_onadmission_bf: doc.followUpOnAdmission.bf,
        followup_onadmission_bicorbonate: doc.followUpOnAdmission.bicorbonate,
        followup_onadmission_bloodculture: doc.followUpOnAdmission.bloodCulture,
        followup_onadmission_chloride: doc.followUpOnAdmission.chloride,
        followup_onadmission_cxrfindings: doc.followUpOnAdmission.cxrFindings,
        followup_onadmission_date: doc.followUpOnAdmission.date,
        followup_onadmission_g6pdResult: doc.followUpOnAdmission.g6pdResult,
        followup_onadmission_g6pdscreening:
          doc.followUpOnAdmission.g6pdScreening,
        followup_onadmission_haemoglobintype:
          doc.followUpOnAdmission.haemoglobinType,
        followup_onadmission_hbgenotype: doc.followUpOnAdmission.hbGenotype,
        followup_onadmission_platelet: doc.followUpOnAdmission.platelet,
        followup_onadmission_potassium: doc.followUpOnAdmission.potassium,
        followup_onadmission_pvcdischarge: doc.followUpOnAdmission.pvcDischarge,
        followup_onadmission_seriealbloodpressure:
          doc.followUpOnAdmission.seriealBloodPressure,
        followup_onadmission_sodium: doc.followUpOnAdmission.sodium,
        followup_onadmission_urea: doc.followUpOnAdmission.urea,
        followup_onadmission_creatinine: doc.followUpOnAdmission.creatinine,
        followup_onadmission_urinalysis: this.DestructUniralysis(
          doc.followUpOnAdmission ?   doc.followUpOnAdmission.urinalysis : ""
        ),
        followup_onadmission_urinemicroscopy:
          doc.followUpOnAdmission.urineMicroscopy,
        followup_onadmission_wcb: doc.followUpOnAdmission.wcb,

        followup_week2_bf: doc.followUpWeekTwo.bf,
        followup_week2_bicorbonate: doc.followUpWeekTwo.bicorbonate,
        followup_week2_bloodculture: doc.followUpWeekTwo.bloodCulture,
        followup_week2_chloride: doc.followUpWeekTwo.chloride,
        followup_week2_cxrfindings: doc.followUpWeekTwo.cxrFindings,
        followup_week2_date: doc.followUpWeekTwo.date,
        followup_week2_g6pdResult: doc.followUpWeekTwo.g6pdResult,
        followup_week2_g6pdscreening: doc.followUpWeekTwo.g6pdScreening,
        followup_week2_haemoglobintype: doc.followUpWeekTwo.haemoglobinType,
        followup_week2_hbgenotype: doc.followUpWeekTwo.hbGenotype,
        followup_week2_platelet: doc.followUpWeekTwo.platelet,
        followup_week2_potassium: doc.followUpWeekTwo.potassium,
        followup_week2_pvcdischarge: doc.followUpWeekTwo.pvcDischarge,
        followup_week2_seriealbloodpressure:
          doc.followUpWeekTwo.seriealBloodPressure,
        followup_week2_sodium: doc.followUpWeekTwo.sodium,
        followup_week2_urea: doc.followUpWeekTwo.urea,
        followup_week2_creatinine: doc.followUpWeekTwo.creatinine,
        followup_week2_urinalysis: this.DestructUniralysis(
          doc.followUpWeekTwo ?    doc.followUpWeekTwo.urinalysis : ""
        ),
        followup_week2_urinemicroscopy: doc.followUpWeekTwo.urineMicroscopy,
        followup_week2_wcb: doc.followUpWeekTwo.wcb,

        followup_week4_bf: doc.followUpWeekFour.bf,
        followup_week4_bicorbonate: doc.followUpWeekFour.bicorbonate,
        followup_week4_bloodculture: doc.followUpWeekFour.bloodCulture,
        followup_week4_chloride: doc.followUpWeekFour.chloride,
        followup_week4_cxrfindings: doc.followUpWeekFour.cxrFindings,
        followup_week4_date: doc.followUpWeekFour.date,
        followup_week4_g6pdResult: doc.followUpWeekFour.g6pdResult,
        followup_week4_g6pdscreening: doc.followUpWeekFour.g6pdScreening,
        followup_week4_haemoglobintype: doc.followUpWeekFour.haemoglobinType,
        followup_week4_hbgenotype: doc.followUpWeekFour.hbGenotype,
        followup_week4_platelet: doc.followUpWeekFour.platelet,
        followup_week4_potassium: doc.followUpWeekFour.potassium,
        followup_week4_pvcdischarge: doc.followUpWeekFour.pvcDischarge,
        followup_week4_seriealbloodpressure:
          doc.followUpWeekFour.seriealBloodPressure,
        followup_week4_sodium: doc.followUpWeekFour.sodium,
        followup_week4_urea: doc.followUpWeekFour.urea,
        followup_week4_creatinine: doc.followUpWeekFour.creatinine,
        followup_week4_urinalysis: this.DestructUniralysis(
          doc.followUpWeekFour ? doc.followUpWeekFour.urinalysis : ""
        ),
        followup_week4_urinemicroscopy: doc.followUpWeekFour.urineMicroscopy,
        followup_week4_wcb: doc.followUpWeekFour.wcb,

        followup_week8_bf: doc.followUpWeekEight.bf,
        followup_week8_bicorbonate: doc.followUpWeekEight.bicorbonate,
        followup_week8_bloodculture: doc.followUpWeekEight.bloodCulture,
        followup_week8_chloride: doc.followUpWeekEight.chloride,
        followup_week8_cxrfindings: doc.followUpWeekEight.cxrFindings,
        followup_week8_date: doc.followUpWeekEight.date,
        followup_week8_g6pdResult: doc.followUpWeekEight.g6pdResult,
        followup_week8_g6pdscreening: doc.followUpWeekEight.g6pdScreening,
        followup_week8_haemoglobintype: doc.followUpWeekEight.haemoglobinType,
        followup_week8_hbgenotype: doc.followUpWeekEight.hbGenotype,
        followup_week8_platelet: doc.followUpWeekEight.platelet,
        followup_week8_potassium: doc.followUpWeekEight.potassium,
        followup_week8_pvcdischarge: doc.followUpWeekEight.pvcDischarge,
        followup_week8_seriealbloodpressure:
          doc.followUpWeekEight.seriealBloodPressure,
        followup_week8_sodium: doc.followUpWeekEight.sodium,
        followup_week8_urea: doc.followUpWeekEight.urea,
        followup_week8_creatinine: doc.followUpWeekEight.creatinine,
        followup_week8_urinalysis: this.DestructUniralysis(
          doc.followUpWeekEight  ? doc.followUpWeekEight.urinalysis : ""
        ),
        followup_week8_urinemicroscopy: doc.followUpWeekEight.urineMicroscopy,
        followup_week8_wcb: doc.followUpWeekEight.wcb,

        followup_week12_bf: doc.followUpWeekTwelve.bf,
        followup_week12_bicorbonate: doc.followUpWeekTwelve.bicorbonate,
        followup_week12_bloodculture: doc.followUpWeekTwelve.bloodCulture,
        followup_week12_chloride: doc.followUpWeekTwelve.chloride,
        followup_week12_cxrfindings: doc.followUpWeekTwelve.cxrFindings,
        followup_week12_date: doc.followUpWeekTwelve.date,
        followup_week12_g6pdResult: doc.followUpWeekTwelve.g6pdResult,
        followup_week12_g6pdscreening: doc.followUpWeekTwelve.g6pdScreening,
        followup_week12_haemoglobintype: doc.followUpWeekTwelve.haemoglobinType,
        followup_week12_hbgenotype: doc.followUpWeekTwelve.hbGenotype,
        followup_week12_platelet: doc.followUpWeekTwelve.platelet,
        followup_week12_potassium: doc.followUpWeekTwelve.potassium,
        followup_week12_pvcdischarge: doc.followUpWeekTwelve.pvcDischarge,
        followup_week12_seriealbloodpressure:
          doc.followUpWeekTwelve.seriealBloodPressure,
        followup_week12_sodium: doc.followUpWeekTwelve.sodium,
        followup_week12_urea: doc.followUpWeekTwelve.urea,
        followup_week12_creatinin: doc.followUpWeekTwelve.creatinin,
        followup_week12_urinalysis: this.DestructUniralysis(
          doc.followUpWeekTwelve  ? doc.followUpWeekTwelve.urinalysis : ""
        ),
        followup_week12_urinemicroscopy: doc.followUpWeekTwelve.urineMicroscopy,
        followup_week12_wcb: doc.followUpWeekTwelve.wcb,
      };
      if (extract) {
        this.computeExcelHeaders(data);
      }
      return data;
    },

    destructurePlaceTreated(list) {
      let present_placetreated = '';
      list.forEach((item, index) => {
        if (index < list.length - 1) {
          present_placetreated += `${item}, `;
        } else {
          present_placetreated += `${item}.`;
        }
      });

      return present_placetreated;
    },
    destructureIllness(list) {
      let illness = '';
      list.forEach((item, index) => {
        if (item.isActive) {
          if (index < list.length - 1) {
            illness += `(Complaint: ${item.complaint}, Duration: ${item.duration}), `;
          } else {
            illness += `(Complaint: ${item.complaint}, Duration: ${item.duration}.`;
          }
        }
      });

      return illness;
    },
    DestructUniralysis(urine) {
      let tempdata = "";
      if (typeof(urine) == 'object') {
        for (var k in urine) {
          let newdata = k + ': ' + urine[k] + ", ";
          tempdata += newdata
        }
      }

      return tempdata;
    },
    async delPatient(item) {
      try {

        await deletADoc.removeDoc('patients', item.id)
        snackbar.$emit('displaySnackbar', {
          msg: 'Patient Deleted',
          color: 'success',
        });
      } catch (err) {
        snackbar.$emit('displaySnackbar', {
          msg: 'Error Occured',
          color: 'error',
        });
        this.loading = false;
      }
    },
    computeExcelHeaders(data) {
      this.excelData = [];
       this.excelHeaders = []
      for (const key in data) {
        this.excelHeaders.push({ label: key, field: key });
      }
    },
    exportChange() {
      if (this.exportMode == 'Export all patients') {
        this.exportoption = false;
        this.exportnameinput = false;
        this.exportyearinput = false;
        this.patientsdataAll = [];
        this.patientsdataAll = this.patientsdataSync;
        this.exporttitle = 'All';
        this.nameFound = '';
        // let currList = this.patientsdataSync.filter((list)=> list)
        this.exportready = false;
      } else if (this.exportMode == 'Export all patients in ado') {
        this.exportnameinput = false;
        this.exportyearinput = false;
        this.exportModeValue = 'ADO';
        this.exportoptions = ['CHOP', 'EPU', 'ALL'];
        this.exportoption = true;
        this.title = 'Entry Point';
        this.nameFound = '';
      } else if (this.exportMode == 'Export all patients in ido') {
        this.exportnameinput = false;
        this.exportyearinput = false;
        this.exportModeValue = 'IDO';
        this.exportoptions = ['CHOP', 'EPU', 'ALL'];
        this.exportoption = true;
        this.title = 'Entry Point';
        this.nameFound = '';
      } else if (this.exportMode == 'Export individual patients') {
        this.nameFound = '';
        this.exportoption = false;
        this.exportnameinput = true;
        this.exportyearinput = false;
        let hosp_no = this.patient_hosp_name;
        hosp_no;
      } else if (this.exportMode == 'Export all patients By year') {
        this.nameFound = '';
        this.exportyearinput = true;
        this.exportnameinput = false;
      }
    },

    initaitExportByLocationAndEntry() {
      let currlocation = this.exportModeValue;
      let currEntry = this.exportoptionvalue;
      this.patientsdataAll = [];
      if (currEntry == 'ALL') {
        let temp = this.patientsdataSync.filter((item) => {
          return item.location == currlocation;
        });
        this.patientsdataAll = temp;
        this.exportready = false;
      } else {
        let temp = this.patientsdataSync.filter((item) => {
          return item.entry_point == currEntry && item.location == currlocation;
        });
        this.patientsdataAll = temp;
        this.exportready = false;
      }
    },
    initaitExportByHospName() {
      this.nameFound = '';
      let currhosp_name = this.patient_hosp_name;
      this.patientsdataAll = [];
      let temp = this.patientsdataSync.filter((item) => {
        return item.hosp_no == currhosp_name;
      });
      this.patientsdataAll = temp;
      if (temp[0]) {
        this.nameFound = this.patientsdataAll[0].name;
        this.title = this.nameFound;
        this.exportready = false;
      }
    },
    initaitExportByYear() {
      this.nameFound = '';
      this.patientsdataAll = [];
      let temp = this.patientsdataSync.filter((item) => {
        return parseInt(item.createdYear) == this.exportyear;
      });
      this.patientsdataAll = temp;

      if (temp.length > 0) {
        this.exportready = false;
      }
    },
  },
};
</script>
